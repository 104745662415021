.contact-us-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  color: #ffffff;
  overflow: hidden;
}

.contact-us-page header {
  padding: 20px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(19, 7, 46, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.contact-us-page .logo {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.nav-links {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 0;
  position: relative;
}

.contact-us-page .nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.contact-us-page .nav-links a:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.contact-us-page .nav-links a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #c9b3ec, #8a63d2);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.contact-us-page .nav-links a:hover::before {
  width: 80%;
}

.contact-us-page .nav-links a.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.contact-us-page main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  min-height: 100vh;
}

.contact-container {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  width: 100%;
}

.section-content {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s ease-out forwards;
  background: rgba(255, 255, 255, 0.05);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.section-content h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  background: linear-gradient(45deg, #c9b3ec, #8a63d2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
  margin-top: 0;
}

.section-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.contact-form {
  background: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 15px;
  margin: 30px 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #ffffff;
  font-size: 1.1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-group input {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #000000;
}

.form-group textarea {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #8a63d2;
  box-shadow: 0 0 10px rgba(138, 99, 210, 0.3);
}

.form-group input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.submit-button {
  background: linear-gradient(45deg, #8a63d2, #c9b3ec);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(138, 99, 210, 0.3);
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(138, 99, 210, 0.4);
}

.contact-info {
  margin-top: 3rem;
  text-align: center;
}

.info-items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
}

.info-item {
  flex: 1;
  min-width: 250px;
  background: rgba(255, 255, 255, 0.05);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.info-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #c9b3ec, #8a63d2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.info-item p {
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
}

.info-item p a {
  color: #c9b3ec;
  text-decoration: underline;
  transition: all 0.3s ease;
}

.info-item p a:hover {
  color: #ffffff;
  text-shadow: 0 0 10px rgba(201, 179, 236, 0.5);
  transform: scale(1.05);
  display: inline-block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .contact-us-page {
    padding: 20px;
  }
  
  .section-content {
    padding: 20px;
  }
  
  .section-content h2 {
    font-size: 2rem;
  }
  
  .info-items {
    flex-direction: column;
  }
  
  .info-item {
    min-width: auto;
  }
  
  .contact-us-page .nav-links {
    gap: 15px;
  }
  
  .contact-us-page .nav-links a {
    font-size: 1rem;
  }
}

.footer {
  background-color: white;
  padding: 80px 80px 40px;
  color: #333;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 80px;
}

.footer-left {
  flex: 0 0 30%;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.footer-logo img {
  height: 45px;
  width: auto;
}

.footer-logo span {
  font-weight: 600;
  font-size: 1.3rem;
  color: #333;
}

.newsletter-signup p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  line-height: 1.6;
}

.email-input-container {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 25px;
  overflow: hidden;
  max-width: 400px;
  transition: border-color 0.3s ease;
  margin-bottom: 30px;
}

.email-input-container:focus-within {
  border-color: #3F2182;
}

.email-input-container input {
  flex: 1;
  padding: 12px 20px;
  border: none;
  outline: none;
  font-size: 1rem;
}

.submit-arrow {
  background: none;
  border: none;
  padding: 0 25px;
  cursor: pointer;
  font-size: 1.4rem;
  color: #3F2182;
  transition: transform 0.2s;
}

.submit-arrow:hover {
  transform: translateX(5px);
}

.footer-links {
  display: flex;
  gap: 100px;
  flex: 1;
  justify-content: flex-end;
  padding-top: 10px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 25px;
  color: #333;
  font-weight: 600;
}

.footer-section a {
  color: #666;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 1.05rem;
  transition: color 0.2s, transform 0.2s;
  display: inline-block;
  line-height: 1.4;
}

.footer-section a:hover {
  color: #3F2182;
  transform: translateX(5px);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin-top: 20px;
  border-top: 1px solid #eee;
  font-size: 0.95rem;
  color: #666;
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 60px 40px 30px;
  }
  
  .footer-links {
    gap: 60px;
  }
  
  .footer-left {
    flex: 0 0 35%;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 20px 25px;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 40px;
  }
  
  .footer-left {
    flex: 1;
  }
  
  .footer-links {
    gap: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .footer-section {
    min-width: 160px;
  }
  
  .email-input-container {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 20px;
  }
  
  .footer-section a {
    margin-bottom: 12px;
  }
} 