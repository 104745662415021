#chatCon {
    width: 380px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  }
  
  #chatCon .chat-box {
    width: 100%;
    height: 550px;
    border-radius: 20px;
    background-color: #fff;
    position: absolute;
    bottom: 70px;
    right: 0;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    transform: translateY(20px) scale(0.95);
    pointer-events: none;
  }
  
  #chatCon .chat-box.active {
    opacity: 1;
    transform: translateY(0) scale(1);
    pointer-events: all;
  }
  
  #chatCon .chat-box .header {
    background: linear-gradient(135deg, #3F2182 0%, #562db3 100%);
    padding: 20px;
    border-radius: 20px 20px 0 0;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #chatCon .chat-box .header .close-chat {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s;
  }
  
  #chatCon .chat-box .header .close-chat:hover {
    opacity: 1;
  }
  
  #chatCon .chat-box .msg-area {
    overflow-y: auto;
    height: 420px;
    padding: 15px;
    scroll-behavior: smooth;
  }
  
  #chatCon .chat-box .msg-area::-webkit-scrollbar {
    width: 6px;
  }
  
  #chatCon .chat-box .msg-area::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
  }
  
  #chatCon .chat-box .msg-area .message {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }
  
  #chatCon .chat-box .msg-area .left {
    align-self: flex-start;
  }
  
  #chatCon .chat-box .msg-area .right {
    align-self: flex-end;
  }
  
  #chatCon .chat-box .msg-area .message span {
    padding: 12px 16px;
    font-size: 15px;
    border-radius: 15px;
    position: relative;
    display: inline-block;
  }
  
  #chatCon .chat-box .msg-area .left span {
    background-color: #f0f2f5;
    color: #000;
    border-bottom-left-radius: 5px;
  }
  
  #chatCon .chat-box .msg-area .right span {
    background: linear-gradient(135deg, #3F2182 0%, #562db3 100%);
    color: #fff;
    border-bottom-right-radius: 5px;
  }
  
  #chatCon .chat-box .msg-area .timestamp {
    font-size: 11px;
    color: #999;
    margin-top: 5px;
    margin-left: 5px;
  }
  
  #chatCon .chat-box .msg-area .avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
  }
  
  #chatCon .chat-box .msg-area .typing-indicator {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  #chatCon .chat-box .msg-area .typing-indicator span {
    height: 8px;
    width: 8px;
    background: #3F2182;
    border-radius: 50%;
    margin: 0 2px;
    display: inline-block;
    animation: typing 1s infinite;
  }
  
  #chatCon .chat-box .msg-area .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  #chatCon .chat-box .msg-area .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes typing {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  #chatCon .chat-box .footer {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid #eee;
    border-radius: 0 0 20px 20px;
  }
  
  #chatCon .chat-box .footer input {
    flex: 1;
    border: none;
    background-color: #f0f2f5;
    padding: 12px 15px;
    border-radius: 20px;
    font-size: 14px;
    color: #000;
    transition: background-color 0.3s;
  }
  
  #chatCon .chat-box .footer input:focus {
    outline: none;
    background-color: #e4e6eb;
  }
  
  #chatCon .chat-box .footer button {
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    background: linear-gradient(135deg, #3F2182 0%, #562db3 100%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
  }
  
  #chatCon .chat-box .footer button:hover {
    transform: scale(1.05);
  }
  
  #chatCon .chat-box .footer button svg {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
  
  #chatCon .pop {
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: linear-gradient(135deg, #3F2182 0%, #562db3 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 20px rgba(63, 33, 130, 0.3);
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
  }
  
  #chatCon .pop:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(63, 33, 130, 0.4);
  }
  
  #chatCon .pop svg {
    fill: #fff;
    width: 28px;
    height: 28px;
  }
  
  @media (max-width: 480px) {
    #chatCon {
      width: 100%;
      right: 0;
      bottom: 0;
    }
    
    #chatCon .chat-box {
      width: 100%;
      height: 100vh;
      border-radius: 0;
      bottom: 0;
    }
    
    #chatCon .pop {
      right: 20px;
      bottom: 20px;
    }
  }
