/* General page styling */
.futuristic-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom right,#13072E 50%, #3F2182 100%);
  position: relative;
  padding: 0 5%;
}

/* Logo styling */
.logo-container {
  position: absolute;
  top: 2%;
  left: 2%;
}

.login-logo {
  width: 100px;
  height: auto;
}

/* Login container styling */
.futuristic-login-container {
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.login-title {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 2rem;
}

/* Input group styling */
.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.input-icon {
  margin-right: 10px;
}

input {
  border: none;
  background: none;
  outline: none;
  color: white;
  font-size: 1rem;
  flex-grow: 1;
}

/* Error message */
.error-message {
  color: #ff4d4d;
  margin-bottom: 20px;
}

/* Button styling */
.futuristic-login-button {
  background-color: hsl(259, 80%, 62%);
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 3px hsl(259, 95%, 46%); /* Further reduced glow */
}

.futuristic-login-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  z-index: 1;
  transition: all 0.3s ease;
}

.futuristic-login-button:hover {
  background-color: hsl(259, 95%, 46%);
  box-shadow: 0 0 5px hsl(259, 95%, 46%), 0 0 10px hsl(259, 95%, 46%); /* Reduced hover glow */
}

.futuristic-login-button:hover::before {
  left: -100%;
  top: -100%;
}

/* Newsletter container styling */
.newsletter-container {
  flex: 1;
  max-width: 400px;
  color: white;
  text-align: center;
}

.newsletter-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.newsletter-container p {
  margin-bottom: 1rem;
}

.newsletter-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
}

.newsletter-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.content-container {
  flex: 1;
  max-width: 600px;
  color: white;
  text-align: center;
  margin-right: 40px;
}

.academy-description h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.academy-description p {
  margin-bottom: 2rem;
  line-height: 1.6;
}

.news-ticker {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.news-ticker h3 {
  margin-bottom: 1rem;
}

.news-ticker p {
  font-style: italic;
}

/* Add styles for the tab buttons */
.tab-container {
  display: flex;
  justify-content: space-around; /* Space out the buttons evenly */
  margin-bottom: 20px; /* Add some space below the tabs */
}

.tab-button {
  background-color: transparent; /* Make the background transparent */
  border: 2px solid #3F2182; /* Border color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for the buttons */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  font-size: 1rem; /* Font size */
  flex: 1; /* Allow buttons to take equal space */
}

.tab-button:hover {
  background-color: #5b39a4; /* Change background on hover */
  color: white; /* Change text color on hover */
}

.tab-button.active {
  background-color: #3F2182; /* Active tab background */
  color: white; /* Active tab text color */
}

.success-message {
  color: green; /* Set the text color to green */
  font-weight: bold; /* Optional: make the text bold */
  margin-top: 10px; /* Optional: add some space above the message */
}
