@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto;
}

.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  overflow-y: auto;
}

.landing-page .logo:hover {
  transform: scale(1.1);
}

.landing-page header {
  padding: 20px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(19, 7, 46, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.landing-page .logo {
  width: 100px;
  height: auto;
}

.landing-page main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  height: 100vh;
}

.landing-page main section {
  min-height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 20px 20px;
}

.hero-section {
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
}

.hero-section .content-container {
  min-height: auto;
  padding: 0 5%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page .content-container {
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 rgba(31, 38, 135, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  width: 90%;
  max-width: 1300px;
  min-height: 100vh;
  margin: 0 auto;
  transition: transform 0.5s ease-in-out;
  padding-top: 120px;
}

.landing-page .main-text {
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: bold;
  margin-bottom: 3rem;
  line-height: 1.2;
  color: white;
  text-align: center;
}

.landing-page .start-for-free {
  font-size: 1.1rem;
  padding: 16px 32px;
  background: white;
  color: #13072E;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-family: 'Poppins', sans-serif;
}

.landing-page .start-for-free:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.landing-page .start-for-free .button-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  color: #13072E;
  font-weight: 500;
}

.landing-page .start-for-free .arrow {
  margin-left: 8px;
  color: #13072E;
  font-weight: 500;
  display: inline;
  background: none;
  width: auto;
  height: auto;
}

.landing-page .nav-links {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 0;
}

.landing-page .nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.landing-page .nav-links a:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.landing-page .nav-links a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #c9b3ec, #8a63d2);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.landing-page .nav-links a:hover::before {
  width: 80%;
}

.landing-page .nav-links a.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.sign-up-button {
  background: white;
  color: #13072E;
  border: none;
  padding: 12px 28px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 20px;
  font-family: 'Poppins', sans-serif;
}

.sign-up-button:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.pricing-section {
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 5%;
  box-shadow: 0 4px 16px 0 rgba(31, 38, 135, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;
  width: 90%;
  max-width: 1300px;
  margin: 5vh auto;
}

.pricing-box {
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  min-height: 500px;
  box-shadow: 0 4px 16px 0 rgba(12, 27, 95, 0.789);
  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.faq-section {
  background: #1A0B3E;
  padding: 80px 20px;
  min-height: auto !important;
}

.faq-content {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  gap: 80px;
  align-items: flex-start;
}

.faq-left {
  flex: 1;
  position: sticky;
  top: 120px;
}

.faq-right {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-left h2 {
  color: white;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}

.faq-left p {
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-us-button {
  background: white;
  color: #1A0B3E;
  border: none;
  padding: 12px 28px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.contact-us-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.faq-item {
  background: #2D1B5A;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-question {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.toggle-icon {
  color: white;
  font-size: 24px;
  font-weight: 300;
  transition: transform 0.3s ease;
}

.faq-item.expanded .toggle-icon {
  transform: rotate(45deg);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 24px;
  color: white;
  opacity: 0;
  font-size: 16px;
  line-height: 1.6;
}

.faq-answer.show {
  max-height: 500px;
  padding: 0 24px 24px;
  opacity: 1;
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.faq-answer p {
  margin: 15px 0;
  white-space: pre-line;
}

.faq-answer ul {
  list-style: none;
  padding-left: 20px;
  margin: 15px 0;
}

.faq-answer li {
  position: relative;
  padding-left: 25px;
  margin: 10px 0;
}

.faq-answer li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #8a63d2;
}

.faq-answer strong {
  color: #fff;
  font-weight: 600;
}

.faq-answer .example {
  margin: 10px 0;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-style: italic;
}

.faq-answer .calculation {
  font-family: monospace;
  background: rgba(255, 255, 255, 0.05);
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 2px;
}

.faq-answer .definition {
  font-weight: 500;
  color: #fff;
  margin-right: 8px;
}

.faq-answer .separator {
  margin: 15px 0;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.faq-item:hover {
  background: rgba(63, 33, 130, 0.5);
}

.faq-item.expanded {
  background: rgba(63, 33, 130, 0.5);
}

.pricing-box h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: white;
}

.pricing-box ul {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
}

.pricing-box li {
  margin: 50px 0;
  position: relative;
  padding-left: 20px;
  color: white;
}

.pricing-box li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #004E92;
}

.pricing-box .start-for-free {
  font-size: 1rem;
  padding: 10px 20px;
  margin-top: auto;
  background: #004E92;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pricing-box .start-for-free:hover {
  background: rgba(0, 78, 146, 0.8);
}

@keyframes glassGlow {
  0%, 100% {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6),
                0 0 10px rgba(255, 255, 255, 0.6);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7),
                 0 0 20px rgba(255, 255, 255, 0.7);
  }
}

@keyframes subtlePulse {
  0%, 100% {
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  50% {
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  }
}

@media screen and (max-width: 1024px) {
  .landing-page header {
    height: auto;
    padding: 15px;
    flex-wrap: wrap;
  }

  .landing-page .nav-links {
    width: 100%;
    justify-content: space-around;
    margin-top: 15px;
  }

  .landing-page .nav-links a {
    font-size: 1.1rem;
  }

  .pricing-section {
    justify-content: center;
  }

  .faq-content {
    flex-direction: column;
    gap: 40px;
  }

  .faq-left {
    position: relative;
    top: 0;
  }
}

@media screen and (max-width: 768px) {
  .landing-page .logo {
    width: 80px;
  }

  .landing-page .start-for-free {
    font-size: 1.2rem;
    padding: 12px 25px;
  }

  .landing-page .arrow {
    margin-left: 40px;
    width: 25px;
    height: 25px;
  }

  .pricing-box {
    width: 100%;
    margin: 10px 0;
  }

  .faq-left h2 {
    font-size: 36px;
  }
  
  .faq-left p {
    font-size: 20px;
  }
  
  .faq-question h3 {
    font-size: 18px;
  }

  .landing-page main section {
    padding: 100px 16px 16px;
  }

  .bootcamp-content {
    padding: 40px 24px;
  }
  
  .bootcamp-section h2 {
    font-size: 1.8rem;
  }
  
  .bootcamp-section p {
    font-size: 1rem;
  }
  
  .explore-courses {
    width: 100%;
    justify-content: center;
  }

  .faq-content {
    padding: 24px;
  }

  .faq-section {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 480px) {
  .landing-page header {
    padding: 10px;
  }

  .landing-page .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .landing-page .nav-links a {
    font-size: 1rem;
  }

  .landing-page .start-for-free {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .landing-page .arrow {
    margin-left: 20px;
    width: 20px;
    height: 20px;
  }

  .pricing-box li {
    margin: 30px 0;
    font-size: 0.9rem;
  }

  .faq-left h2 {
    font-size: 28px;
  }
  
  .faq-left p {
    font-size: 18px;
  }
  
  .faq-question {
    padding: 16px;
  }
  
  .faq-question h3 {
    font-size: 16px;
  }
  
  .faq-answer.show {
    padding: 0 16px 16px;
  }
}

/* Add support for larger screens */
@media screen and (min-width: 1440px) {
  .landing-page .content-container,
  .pricing-section,
  .faq-section {
    max-width: 1400px;
  }

  .landing-page .main-text {
    font-size: 4.5rem;
  }
}

/* Add support for ultra-wide screens */
@media screen and (min-width: 1920px) {
  .landing-page .content-container,
  .pricing-section,
  .faq-section {
    max-width: 1600px;
  }
}

.bootcamp-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #13072E;
}

.bootcamp-content {
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  background: linear-gradient(to right, rgba(63, 33, 130, 0.5), rgba(63, 33, 130, 0.3));
  border-radius: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  padding: 80px;
  position: relative;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.bootcamp-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 600px;
}

.bootcamp-tag {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  width: fit-content;
  letter-spacing: 0.5px;
}

.bootcamp-section h2 {
  color: white;
  font-size: 3.5rem;
  margin: 0;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: -0.5px;
}

.bootcamp-section p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
}

.bootcamp-right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bootcamp-right .image-container {
  width: 100%;
  height: 400px;
  border-radius: 32px;
  overflow: hidden;
}

.bootcamp-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.explore-courses {
  background: white;
  color: #13072E;
  border: none;
  padding: 16px 32px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  margin-top: 16px;
}

.explore-courses:hover {
  background: rgba(255, 255, 255, 0.95);
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.explore-courses .arrow {
  margin-left: 8px;
  font-size: 1.2rem;
}

@media screen and (max-width: 1200px) {
  .bootcamp-content {
    padding: 60px;
    gap: 60px;
  }

  .bootcamp-section h2 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .bootcamp-content {
    flex-direction: column;
    text-align: left;
    padding: 40px;
  }

  .bootcamp-section p {
    max-width: 100%;
  }

  .bootcamp-right .image-container {
    height: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .bootcamp-section {
    padding: 20px;
  }

  .bootcamp-content {
    border-radius: 32px;
    padding: 32px;
  }

  .bootcamp-section h2 {
    font-size: 2.5rem;
  }
  
  .bootcamp-section p {
    font-size: 1.1rem;
  }

  .bootcamp-right .image-container {
    height: 300px;
  }
  
  .explore-courses {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .bootcamp-content {
    padding: 24px;
  }

  .bootcamp-section h2 {
    font-size: 2rem;
  }

  .bootcamp-section p {
    font-size: 1rem;
  }

  .bootcamp-right .image-container {
    height: 250px;
  }
}

.features-section {
  padding: 80px 20px;
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
}

.features-wrapper {
  max-width: 1300px;
  margin: 0 auto;
}

.features-title {
  font-size: 3.5rem;
  color: white;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 600;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-bottom: 60px;
}

.feature-box {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.feature-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.feature-tag {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 500;
  width: fit-content;
}

.feature-heading {
  font-size: 1.8rem;
  color: white;
  margin: 0;
  line-height: 1.3;
  font-weight: 600;
  min-height: 2.6em;
  display: flex;
  align-items: flex-start;
}

.feature-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
}

.enroll-now-button {
  background: white;
  color: #13072E;
  border: none;
  padding: 20px 40px;
  border-radius: 100px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Poppins', sans-serif;
  margin-top: 24px;
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
}

.enroll-now-button:hover {
  background: rgba(255, 255, 255, 0.95);
  transform: translateY(-2px);
  box-shadow: 0 8px 32px rgba(255, 255, 255, 0.15);
}

.enroll-now-button .arrow {
  font-size: 1.4rem;
  transition: transform 0.3s ease;
}

.enroll-now-button:hover .arrow {
  transform: translateX(4px);
}

@media screen and (max-width: 1024px) {
  .features-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
  }
  
  .features-title {
    font-size: 2.8rem;
  }
  
  .feature-heading {
    font-size: 1.6rem;
  }

  .enroll-now-button {
    width: 100%;
    justify-content: center;
    padding: 16px 32px;
    font-size: 1.1rem;
  }
}

.blog-section {
  padding: 108px 0;
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  align-items: center;
}

.blog-title {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 72px;
  width: 100%;
  max-width: 1380px;
}

.blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-bottom: 54px;
  width: 100%;
  max-width: 1380px;
  padding: 0;
}

.blog-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.blog-card-link:hover {
  text-decoration: none;
}

.blog-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 36px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blog-card-link:hover .blog-card {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.08);
}

.blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.3;
}

.blog-card p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.blog-image {
  width: 100%;
  height: 270px;
  border-radius: 16px;
  margin-bottom: 27px;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition: transform 0.3s ease;
}

.blog-card-link:hover .blog-image img {
  transform: scale(1.05);
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.blog-cta {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  max-width: 1380px;
}

.subscribe-button {
  font-size: 1.1rem;
  padding: 16px 32px;
  background: white;
  color: #13072E;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

.subscribe-button:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.subscribe-button .arrow {
  margin-left: 8px;
  color: #13072E;
  font-weight: 500;
  display: inline;
  background: none;
  width: auto;
  height: auto;
}

@media screen and (max-width: 1440px) {
  .blog-container {
    max-width: 1150px;
    margin: 0 40px 54px 40px;
  }
}

@media screen and (max-width: 1024px) {
  .blog-section {
    padding: 72px 0;
  }
  
  .blog-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 0 30px 54px 30px;
    max-width: 920px;
  }
}

@media screen and (max-width: 768px) {
  .blog-section {
    padding: 54px 0;
  }
  
  .blog-container {
    grid-template-columns: 1fr;
    gap: 24px;
    margin: 0 20px 36px 20px;
    max-width: 575px;
  }
  
  .blog-card {
    padding: 27px;
  }
  
  .blog-title {
    font-size: 36px;
    margin-bottom: 36px;
    padding: 0 20px;
  }
}

.footer {
  background-color: white;
  padding: 80px 80px 40px;
  color: #333;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 80px;
}

.footer-left {
  flex: 0 0 30%;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.footer-logo img {
  height: 45px;
  width: auto;
}

.footer-logo span {
  font-weight: 600;
  font-size: 1.3rem;
  color: #333;
}

.newsletter-signup p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  line-height: 1.6;
}

.email-input-container {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 25px;
  overflow: hidden;
  max-width: 400px;
  transition: border-color 0.3s ease;
  margin-bottom: 30px;
}

.email-input-container:focus-within {
  border-color: #3F2182;
}

.email-input-container input {
  flex: 1;
  padding: 12px 20px;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #000;
}

.submit-arrow {
  background: none;
  border: none;
  padding: 0 25px;
  cursor: pointer;
  font-size: 1.4rem;
  color: #3F2182;
  transition: transform 0.2s;
}

.submit-arrow:hover {
  transform: translateX(5px);
}

.footer-links {
  display: flex;
  gap: 100px;
  flex: 1;
  justify-content: flex-end;
  padding-top: 10px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 25px;
  color: #333;
  font-weight: 600;
}

.footer-section a {
  color: #666;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 1.05rem;
  transition: color 0.2s, transform 0.2s;
  display: inline-block;
  line-height: 1.4;
}

.footer-section a:hover {
  color: #3F2182;
  transform: translateX(5px);
}

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-links a {
  color: #3F2182;
  font-size: 1.5rem;
  transition: transform 0.2s ease, color 0.2s ease;
}

.social-links a:hover {
  transform: translateY(-3px);
  color: #13072E;
}

.join-community {
  display: none;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin-top: 20px;
  border-top: 1px solid #eee;
  font-size: 0.95rem;
  color: #666;
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 60px 40px 30px;
  }
  
  .footer-links {
    gap: 60px;
  }
  
  .footer-left {
    flex: 0 0 35%;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 20px 25px;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 40px;
  }
  
  .footer-left {
    flex: 1;
  }
  
  .footer-links {
    gap: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .footer-section {
    min-width: 160px;
  }
  
  .email-input-container {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 20px;
  }
  
  .footer-section a {
    margin-bottom: 12px;
  }
}