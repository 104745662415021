@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap');

.about-us-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #13072E;
  color: #ffffff;
  overflow: hidden;
}

.about-us-page header {
  padding: 20px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(19, 7, 46, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.about-us-page .logo {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.about-us-page .nav-links {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 0;
  position: relative;
  margin-right: 20px;
}

.nav-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
}

.about-us-page .nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.about-us-page .nav-links a:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.about-us-page .nav-links a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #c9b3ec, #8a63d2);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.about-us-page .nav-links a:hover::before {
  width: 80%;
}

.about-us-page .nav-links a.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.about-us-page main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  min-height: 100vh;
}

.about-content {
  flex: 1;
  width: 100%;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.about-content section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  position: relative;
}

.section-content {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s ease-out forwards;
  background: rgba(255, 255, 255, 0.05);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.section-content h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  background: linear-gradient(45deg, #c9b3ec, #8a63d2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: center;
  margin-top: 0;
}

.section-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: justify;
}

.section-content ul {
  list-style: none;
  padding: 0;
}

.section-content li {
  margin: 15px 0;
  padding-left: 25px;
  position: relative;
  text-align: left;
}

.section-content li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: #c9b3ec;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-image {
  display: none;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevent double scrollbars */
  }
  
  .aboutus {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure it takes the full viewport height */
    overflow: hidden; /* Prevent double scrollbars */
  }
  
  .main {
    flex: 1;
    overflow-y: auto; /* Allow vertical scrolling */
  }
  
  .content-aboutus {
    padding: 20px;
  }
  
  .content-aboutus {
    padding: 20px;
  }

  .section-image-right {
    display: flex;
    justify-content: flex-end;;
    align-items: center;
    padding: 20px;
  }

  .section-image-right img {
    width: 30vw; /* 30% of the viewport width */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .redefine-section {
/* Full width of the viewport */
    margin-left: calc(-50vw + 50%); /* Center the section */
    padding: 20px;
    /* Remove border radius for full width */
    backdrop-filter: blur(10px);
  }
  
  .redefine-section .section-content-redefine {
    max-width: 100%;

    
    margin: 0 auto;
  }

  .section-content-redefine p {
    font-size: 2rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #ffffff;
    
  }

  .section-content-redefine h2{
    font-size: 3rem;
    margin-bottom: 30px;
    background: linear-gradient(45deg, #c9b3ec, #8a63d2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    text-align: center;
  }

  .welcome-section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    position: relative;
    background: #13072E;
  }

  .welcome-container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    padding: 40px;
  }

  .section-content-welcome {
    flex: 1;
    max-width: 900px;
  }

  .welcome-text {
    background: linear-gradient(135deg, rgba(63, 33, 130, 0.5) 0%, rgba(63, 33, 130, 0.3) 100%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 30px;
    padding: 40px;
    margin-top: 40px;
  }

  .section-content-welcome h1 {
    font-size: 4.5rem;
    font-weight: bold;
    margin-bottom: 0;
    color: #ffffff;
    line-height: 1.2;
  }

  .section-content-welcome p {
    font-size: 1.25rem;
    line-height: 1.8;
    margin-bottom: 30px;
    color: #ffffff;
  }

  .section-content-welcome p:last-child {
    margin-bottom: 0;
  }

  .welcome-logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .welcome-logo img {
    width: 400px;
    height: auto;
  }

  .sign-up-button {
    background: #ffffff;
    color: #13072E;
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }

  .sign-up-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  }

  .section-content-team h2{
    font-size: 3.5rem;
    margin-bottom: 30px;
    background: linear-gradient(45deg, #c9b3ec, #8a63d2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    text-align: center;
  }
  

  .meet-team .section-content-team {
    max-width: 100%;

    
    margin: 0 auto;
  }

  .section-content-team p {
    font-size: 1.5rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
  }

  .section-content-team h3{
    font-size: 3rem;
    margin-bottom: 30px;
    background: linear-gradient(45deg, #c9b3ec, #8a63d2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    text-align: center;
  }

  .team-members-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 30px;
    padding: 20px;
  }

  .team-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 120px;
    gap: 60px;
    background: rgba(255, 255, 255, 0.02);
    margin: 20px 0;
  }

  .team-member-portrait {
    flex: 0 0 400px;
  }

  .team-member-portrait img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .team-member-detail {
    flex: 1;
    padding: 20px 0;
  }

  .team-member-detail h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .team-member-detail .title {
    font-size: 1.2rem;
    color: #c9b3ec;
    margin-bottom: 30px;
  }

  .team-member-detail p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: justify;
  }

  .meet-team {
    padding: 60px 120px 20px;
  }

  .meet-team-title {
    text-align: center;
    margin-bottom: 60px;
  }

  .meet-team-title h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .meet-team-title p {
    font-size: 1.5rem;
    color: #c9b3ec;
  }

  @media (max-width: 1200px) {
    .team-section {
      padding: 40px 60px;
      gap: 40px;
    }
    
    .team-member-portrait {
      flex: 0 0 300px;
    }
  }

  @media (max-width: 992px) {
    .team-section {
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;
    }
    
    .team-member-portrait {
      flex: 0 0 auto;
      width: 100%;
      max-width: 400px;
      margin-bottom: 30px;
    }
    
    .team-member-detail {
      width: 100%;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .meet-team {
      padding: 40px 20px 20px;
    }
    
    .meet-team-title h1 {
      font-size: 2.5rem;
    }
    
    .meet-team-title p {
      font-size: 1.2rem;
    }
    
    .team-member-detail h2 {
      font-size: 1.8rem;
    }
  }

.footer {
  background-color: white;
  padding: 80px 80px 40px;
  color: #333;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 80px;
}

.footer-left {
  flex: 0 0 30%;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.footer-logo img {
  height: 45px;
  width: auto;
}

.footer-logo span {
  font-weight: 600;
  font-size: 1.3rem;
  color: #333;
}

.newsletter-signup p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  line-height: 1.6;
}

.email-input-container {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 25px;
  overflow: hidden;
  max-width: 400px;
  transition: border-color 0.3s ease;
  margin-bottom: 30px;
}

.email-input-container:focus-within {
  border-color: #3F2182;
}

.email-input-container input {
  flex: 1;
  padding: 12px 20px;
  border: none;
  outline: none;
  font-size: 1rem;
}

.submit-arrow {
  background: none;
  border: none;
  padding: 0 25px;
  cursor: pointer;
  font-size: 1.4rem;
  color: #3F2182;
  transition: transform 0.2s;
}

.submit-arrow:hover {
  transform: translateX(5px);
}

.footer-links {
  display: flex;
  gap: 100px;
  flex: 1;
  justify-content: flex-end;
  padding-top: 10px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  min-width: 180px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 25px;
  color: #333;
  font-weight: 600;
}

.footer-section a {
  color: #666;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 1.05rem;
  transition: color 0.2s, transform 0.2s;
  display: inline-block;
  line-height: 1.4;
}

.footer-section a:hover {
  color: #3F2182;
  transform: translateX(5px);
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin-top: 20px;
  border-top: 1px solid #eee;
  font-size: 0.95rem;
  color: #666;
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 60px 40px 30px;
  }
  
  .footer-links {
    gap: 60px;
  }
  
  .footer-left {
    flex: 0 0 35%;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 20px 25px;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 40px;
  }
  
  .footer-left {
    flex: 1;
  }
  
  .footer-links {
    gap: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .footer-section {
    min-width: 160px;
  }
  
  .email-input-container {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 20px;
  }
  
  .footer-section a {
    margin-bottom: 12px;
  }
}

.vision-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px;
  position: relative;
  background: #13072E;
}

.vision-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 20px;
}

.vision-title h1 {
  font-size: 5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

.vision-title p {
  font-size: 1.5rem;
  color: #ffffff;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.6;
  opacity: 0.9;
}

.vision-container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(63, 33, 130, 0.4) 0%, rgba(45, 20, 96, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.vision-image {
  flex: 0 0 45%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.vision-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px 0 0 100px;
}

.section-content-vision {
  flex: 1;
  padding: 60px;
  max-width: 55%;
}

.section-content-vision h2 {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #ffffff;
  line-height: 1.2;
}

.section-content-vision p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ffffff;
  margin: 0;
  opacity: 0.9;
}

.values-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px;
  position: relative;
  background: #13072E;
}

.values-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 20px;
}

.values-title h1 {
  font-size: 5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

.values-container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(63, 33, 130, 0.4) 0%, rgba(45, 20, 96, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.features-list {
  flex: 1;
  padding: 60px;
  max-width: 55%;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}

.feature-item:last-child {
  margin-bottom: 0;
}

.arrow {
  font-size: 24px;
  color: white;
  line-height: 1.2;
}

.feature-text h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: white;
}

.feature-text p {
  font-size: 16px;
  line-height: 1.8;
  color: #ffffff;
  margin: 0;
  opacity: 0.9;
}

.values-image {
  flex: 0 0 45%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.values-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px 0 0 100px;
}

@media (max-width: 1200px) {
  .values-title h1 {
    font-size: 4rem;
  }
  
  .values-container {
    width: 95%;
  }
}

@media (max-width: 992px) {
  .values-container {
    flex-direction: column;
    border-radius: 30px;
    padding: 40px;
  }
  
  .features-list {
    max-width: 100%;
    padding: 20px;
  }
  
  .values-image {
    width: 100%;
    height: 400px;
  }
  
  .values-image img {
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .values-title h1 {
    font-size: 3rem;
  }
  
  .values-image {
    height: 300px;
  }
  
  .feature-text h2 {
    font-size: 20px;
  }
  
  .feature-text p {
    font-size: 14px;
  }
}

.what-we-offer-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px 20px;
  position: relative;
  background: #13072E;
}

.what-we-offer-title {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 20px;
}

.what-we-offer-title h1 {
  font-size: 5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

.what-we-offer-container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(63, 33, 130, 0.4) 0%, rgba(45, 20, 96, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.what-we-offer-content {
  flex: 1;
  padding: 40px;
  max-width: 55%;
}

.what-we-offer-content .feature-item {
  margin-bottom: 15px;
}

.what-we-offer-content .feature-item:last-child {
  margin-bottom: 0;
}

.what-we-offer-content .feature-text h2 {
  margin-bottom: 4px;
}

.what-we-offer-content .feature-text p {
  line-height: 1.4;
  margin: 0;
}

.what-we-offer-image {
  flex: 0 0 45%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.what-we-offer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 100px 100px 0;
}

@media (max-width: 1200px) {
  .what-we-offer-title h1 {
    font-size: 4rem;
  }
  
  .what-we-offer-container {
    width: 95%;
  }
}

@media (max-width: 992px) {
  .what-we-offer-container {
    flex-direction: column;
    border-radius: 30px;
    padding: 40px;
  }
  
  .what-we-offer-content {
    max-width: 100%;
    padding: 20px;
  }
  
  .what-we-offer-image {
    width: 100%;
    height: 400px;
  }
  
  .what-we-offer-image img {
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .what-we-offer-title h1 {
    font-size: 3rem;
  }
}

.process-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px;
  position: relative;
  background: #13072E;
}

.process-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 20px;
}

.process-title h1 {
  font-size: 5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

.process-container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(63, 33, 130, 0.4) 0%, rgba(45, 20, 96, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.section-content-process {
  flex: 1;
  padding: 60px;
  max-width: 55%;
}

.process-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.process-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.process-item .arrow {
  font-size: 24px;
  color: white;
  line-height: 1.2;
}

.process-text h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: white;
}

.process-text p {
  font-size: 16px;
  line-height: 1.8;
  color: #ffffff;
  margin: 0;
  opacity: 0.9;
}

.process-image {
  flex: 0 0 45%;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}

.process-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 100px 100px 0;
  display: block;
}

@media (max-width: 1200px) {
  .process-title h1 {
    font-size: 4rem;
  }
  
  .process-container {
    width: 95%;
  }
}

@media (max-width: 992px) {
  .process-container {
    flex-direction: column;
    border-radius: 30px;
    padding: 40px;
  }
  
  .section-content-process {
    max-width: 100%;
    padding: 20px;
  }
  
  .process-image {
    width: 100%;
    height: 400px;
    flex: none;
  }
  
  .process-image img {
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .process-title h1 {
    font-size: 3rem;
  }
  
  .process-text h2 {
    font-size: 20px;
  }
  
  .process-text p {
    font-size: 14px;
  }
}

.meet-team {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px;
  position: relative;
  background: #13072E;
}

.meet-team-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 20px;
}

.meet-team-title h1 {
  font-size: 5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

.meet-team-title p {
  font-size: 1.5rem;
  color: #ffffff;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.6;
  opacity: 0.9;
}

.meet-team-container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(135deg, rgba(63, 33, 130, 0.4) 0%, rgba(45, 20, 96, 0.4) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 60px;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.section-content-team {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.section-content-team p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ffffff;
  margin-bottom: 20px;
  opacity: 0.9;
}

.section-content-team p:last-child {
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .meet-team-title h1 {
    font-size: 4rem;
  }
  
  .meet-team-container {
    width: 95%;
  }
}

@media (max-width: 992px) {
  .meet-team-container {
    border-radius: 30px;
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .meet-team-title h1 {
    font-size: 3rem;
  }
  
  .section-content-team p {
    font-size: 1.1rem;
  }
}