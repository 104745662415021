:root {
  --background-dark: #13072E;
  --panel-bg: rgba(19, 7, 46, 0.9);
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --accent-color: #3F2182;
  --border-radius: 12px;
}

.video-detail-container {
  min-height: 100vh;
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
}

.top-bar {
  padding: 20px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--panel-bg);
  z-index: 1000;
  box-sizing: border-box;
}

.left-logo {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.nav-links {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 0;
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.nav-links a:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.nav-links a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #c9b3ec, #8a63d2);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-links a:hover::before {
  width: 80%;
}

.nav-links a.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.content {
  display: flex;
  gap: 2rem;
  padding: 140px 2rem 2rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.main-content {
  flex: 1;
  width: 70%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.video-section {
  background-color: var(--panel-bg);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.video-player {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;
  border-radius: 8px;
}

.video-details {
  background-color: var(--panel-bg);
  padding: 1.5rem;
  border-radius: var(--border-radius);
}

.video-title {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  color: var(--text-primary);
  font-weight: 500;
}

.video-title-divider {
  border: none;
  border-top: 1px solid #9CA3AF;
  margin: 0.5rem 1;
}

.video-description {
  color: #ffffffbf;
  line-height: 1.2;
  margin: 5px 0;
  font-size: 1rem;
}

.vertical-video-list {
  display: flex;
  flex-direction: column; /* Stacks items vertically */
  gap: 20px; /* Adds spacing between items */
  min-width: 30%;
  overflow-y: auto;
  max-height: calc(100vh - 160px);
  max-width: 100%;
}

.related-videos {
  width: 100%;
  background-color: var(--panel-bg);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  height: fit-content;
  max-height: calc(100vh - 160px);
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.logout-button {
  background-color: #f44336 !important;
  color: white !important;
  border: none !important;
  border-radius: 6px !important;
  padding: 8px 16px !important;
  font-size: 0.85rem !important;
  transition: background-color 0.3s !important;
}

.logout-button:hover {
  background-color: #d32f2f !important;
}

.module-title {
  margin: 0 0 1.5rem 0;
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.video-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 280px);
  padding-right: 8px;
  margin-right: -8px;
  scrollbar-gutter: stable;
}

.related-video-item {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: rgba(63, 33, 130, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.05);
  min-height: 85px;
}

.related-video-item:hover {
  background-color: rgba(63, 33, 130, 0.6);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.related-video-item.active {
  background-color: rgba(63, 33, 130, 0.8);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.video-thumbnail {
  width: 120px;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;
  overflow: hidden;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.video-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-info h4 {
  margin: 0 0 0.25rem 0;
  font-size: 0.9rem;
  color: var(--text-primary);
  font-weight: 500;
  line-height: 1.4;
}

.video-description-preview {
  font-size: 0.8rem;
  color: var(--text-secondary);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
}

/* Vertical Video List Scrollbar Styling */
.vertical-video-list::-webkit-scrollbar {
  width: 6px; /* Thin scrollbar */
}

.vertical-video-list::-webkit-scrollbar-track {
  background: transparent;
}

.vertical-video-list::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.5); /* Grayish scrollbar */
  border-radius: 4px;
}

.vertical-video-list::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.7); /* Slightly darker on hover */
}

/* Scrollbar styling */
.video-list::-webkit-scrollbar {
  width: 8px;
}

.video-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  margin: 4px;
}

.video-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  border: 2px solid var(--panel-bg);
}

.video-list::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.25);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .content {
    flex-direction: column;
  }
  
  .main-content {
    width: 100%;
    max-width: 100%;
  }
  
  .related-videos {
    width: 100%;
    position: static;
    max-height: none;
  }
  
  .video-list {
    max-height: 500px;
  }
} 
