@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap');

.dashboard {
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%);
  min-height: 100vh;
  color: #ffffff;
  font-family: 'Rubik', sans-serif;
  overflow-y: auto;
}

.dashboard-header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
  background-color: rgba(19, 7, 46, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-logo {
  width: 100px;
  height: auto;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.dashboard-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem 2rem;
}

.sidebar {
  width: 250px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;
}

.menu-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.main-content {
  width: 100%;
  padding-bottom: 40px;
}

.content-header {
  margin: 1rem 0;
  padding: 0 0.5rem;
}

.content-header h1 {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
}

.content-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  height: 100%;
}

.content-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.content-item img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.content-item-content {
  padding: 1.25rem;
}

.content-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  color: #fff;
}

.content-item p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.75rem;
}

.progress-info {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.logout-button {
  padding: 0.4rem 0.8rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.85rem;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.welcome-message {
  font-size: 1rem;
  color: rgb(173, 130, 227);
}

.content-item.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.banner-overlay {
  width: 100%;
  height: auto;
  min-height: 150px;
  max-height: 250px;
  margin-bottom: 1.5rem;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.banner-overlay img {
  width: 100%;
  height: auto;
  max-height: 250px;
  display: block;
  object-fit: contain;
  object-position: center;
}

@media (max-height: 800px) {
  .banner-overlay {
    min-height: 120px;
    max-height: 200px;
  }
  
  .banner-overlay img {
    max-height: 200px;
  }
  
  .content-item img {
    height: 160px;
  }
  
  .content-item-content {
    padding: 1rem;
  }
}

.nav-links {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 0;
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.nav-links a:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.nav-links a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #c9b3ec, #8a63d2);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-links a:hover::before {
  width: 80%;
}

.nav-links a.active {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
