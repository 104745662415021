.signup-verify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background: linear-gradient(to bottom right, #13072E 50%, #3F2182 100%); /* Shaded gradient */
  color: #f5f7f9;
  text-align: center;
  padding: 20px;
}

.signup-verify-container h1 {
  font-size: 2rem; /* Adjust the size as needed */
  margin: 0; /* Remove default margin */
}

.go-to-login-button {
  margin-top: 20px; /* Space above the button */
  padding: 10px 20px; /* Padding for the button */
  background-color: #3F2182; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.go-to-login-button:hover {
  background-color: #5A3E9D; /* Darker shade on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .signup-verify-container h1 {
      font-size: 1.5rem; /* Smaller font size for mobile */
  }
}